import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import type { OrganizationPlaybook } from "@/interfaces/organization/OrganizationPlaybook";

class OrganizationPlaybookApi extends OrganizationGenericEntityApi<OrganizationPlaybook> {
  apiIdentifier = "organization_playbook";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/playbooks`;
  }

  parseEntity(entityFromApi: any) {
    return new ApiResponseParser(entityFromApi, "organization_playbook")
      .asOrganizationEntity()
      .asString("title")
      .asString("description")
      .asId("deleted_by_user_id")
      .asIds("tag_ids")
      .build() as OrganizationPlaybook;
  }

  getDefaultInclude() {
    return ["tag_ids"];
  }
}

export function useOrganizationPlaybookApi() {
  return new OrganizationPlaybookApi();
}
