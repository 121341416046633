import { useOrganizationPlaybookApi } from "@/api/organization/OrganizationPlaybookApi";
import type { EntityIdentifier } from "@/interfaces/generic/EntityIdentifier";
import type { OrganizationPlaybook } from "@/interfaces/organization/OrganizationPlaybook";
import { getEntityIdentifier } from "@/lib/generic/EntityIdentifierUtils";
import { setEntityNameFields } from "@/lib/generic/StoreUtils";
import { useGenericPusherUtils } from "@/stores/generic/GenericPusherUtils";
import { OrganizationCache } from "@/stores/generic/cache/OrganizationCache";
import { OrganizationEntityStorage } from "@/stores/generic/storage/OrganizationEntityStorage";
import { OrganizationGenericActions } from "@/stores/generic/store/OrganizationGenericActions";
import { defineStore } from "pinia";

export const useOrganizationPlaybooksStore = defineStore(
  "OrganizationPlaybooks",
  () => {
    const storage = new OrganizationEntityStorage<OrganizationPlaybook>();
    const pageCache = new OrganizationCache<OrganizationPlaybook>();

    const subscribeToSocket = (entityIdentifier: EntityIdentifier) => {
      useGenericPusherUtils<OrganizationPlaybook>(
        "playbook",
        storage,
        genericActions.getById,
        pageCache.clearCache
      ).subscribeToPusher(entityIdentifier.organizationId!);
    };

    const genericActions = new OrganizationGenericActions<OrganizationPlaybook>(
      {
        storage: storage,
        pageCache: pageCache,
        entityApi: useOrganizationPlaybookApi(),
        enhanceEntity: enhanceEntity,
        initializationCallback: subscribeToSocket,
      }
    );

    return {
      ...genericActions,
    };
  }
);

export function enhanceEntity(
  entity: OrganizationPlaybook,
  storage: OrganizationEntityStorage<OrganizationPlaybook>
) {
  const entityIdentifier = getEntityIdentifier(entity);

  setEntityNameFields(entity, () => {
    const entity = storage.get(entityIdentifier);
    const title = entity?.title;

    return entity ? title : entityIdentifier.id;
  });
}
