import { OrganizationGenericEntityApi } from "@/api/generic/OrganizationGenericEntityApi";
import { ApiResponseParser } from "@/api/mapping/ApiResponseParser";
import { organizationHasNetworkExchanges } from "@/composables/organization/misc/FeatureFlags";
import type { OrganizationInvoice } from "@/interfaces/organization/invoice/OrganizationInvoice";
import { apiDateToJsObject, dateToApiFormat } from "@/lib/common/DateUtils";
import { useUserStore } from "@/stores/user/User";
import { useOrganizationInvoiceNetworkExchangeApi } from "./OrganizationInvoiceNetworkExchangeApi";
import { useOrganizationInvoicePrefixApi } from "./OrganizationInvoicePrefixApi";

class OrganizationInvoiceApi extends OrganizationGenericEntityApi<OrganizationInvoice> {
  apiIdentifier = "organization_invoice";

  getBaseUrl(args: { organizationId: string }) {
    return `/organizations/${args.organizationId}/invoices`;
  }

  parseEntity(entityFromApi: any) {
    if (entityFromApi.invoice_prefix?.data) {
      entityFromApi.invoice_prefix =
        useOrganizationInvoicePrefixApi().parseEntity(
          entityFromApi.invoice_prefix.data,
        );
    }
    if (entityFromApi.last_peppol_exchange?.data) {
      entityFromApi.last_peppol_exchange =
        entityFromApi.last_peppol_exchange.data.map((element: any) => {
          return useOrganizationInvoiceNetworkExchangeApi().parseEntity(
            element,
          );
        });
    }

    if (entityFromApi?.line_items) {
      entityFromApi.line_items = entityFromApi.line_items.data;

      entityFromApi.line_items.forEach((item: any) => {
        if (item.billing_period_started_at) {
          item.billing_period_started_at = apiDateToJsObject(
            item.billing_period_started_at,
          );
        }

        if (item.billing_period_ended_at) {
          item.billing_period_ended_at = apiDateToJsObject(
            item.billing_period_ended_at,
          );
        }
      });
    }
    if (entityFromApi?.taxes) {
      entityFromApi.taxes = entityFromApi.taxes.data;
    }

    const entity = new ApiResponseParser(entityFromApi, "organization_invoice")
      .asOrganizationEntity()
      .asId("organization_invoice_id")
      .asId("organization_bank_account_id")
      .asId("organization_sender_user_id")
      .asId("organization_receiver_user_id")
      .asId("organization_invoice_prefix_id")
      .asIds("tag_ids")
      .asDate("issued_at")
      .asDate("due_at")
      .asDate("paid_at")
      .asBool("is_reverse")
      .asBool("is_tax_charged")
      .asBool("is_vat_charged")
      .asLangMap("note_lang_map")
      .build() as OrganizationInvoice;

    return entity;
  }

  async email(args: { entity: OrganizationInvoice; params?: any }) {
    return this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.entity.organization_id,
      })}/${args.entity.id}/email`,
      entity: args.params,
    });
  }

  async postPeppolToInstitution(args: {
    entity: OrganizationInvoice;
    institutionId: string;
  }) {
    const newNetworkExchange = await this.baseApiPostEntity({
      url: `${this.getBaseUrl({
        organizationId: args.entity.organization_id,
      })}/${args.entity.id}/peppol/${args.institutionId}`,
      entity: {},
    });

    return useOrganizationInvoiceNetworkExchangeApi().parseEntity(
      newNetworkExchange,
    );
  }

  prepareEntity(entity: any) {
    super.prepareEntity(entity);
    if (entity.issued_at) {
      entity.issued_at = dateToApiFormat(entity.issued_at);
    }

    if (entity.paid_at) {
      entity.paid_at = dateToApiFormat(entity.paid_at);
    }
  }

  getDefaultInclude() {
    const response = ["invoice_prefix", "tag_ids", "line_items", "taxes"];

    const organization = useUserStore().getActiveOrganization().value;
    if (organizationHasNetworkExchanges(organization)) {
      response.push("last_peppol_exchange");
    }

    return response;
  }

  postDefaultInclude() {
    return ["taxes"];
  }
}

export function useOrganizationInvoiceApi() {
  return new OrganizationInvoiceApi();
}
